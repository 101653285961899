<template>
    <section class="section-header" :type="type">
        <h2 v-if="title && !type" class="section-title mb8">{{title}}</h2>
        <h3 v-if="title && type=='h3'" class="section-title mb8">{{title}}</h3>
        <h4 v-if="title && type=='h4'" class="section-title mb8">{{title}}</h4>
        <p v-if="description" class="section-description">{{description}}</p>
    </section>
</template>

<script>
    export default {
        name: "SectionHeader",
        props: {
            title: String,
            description: String,
            type: String
        }
        
    }
</script>

<style lang="sass" scoped>

.section-header    
    padding-top: $mgLg
    margin-top: $mgLg
    margin-bottom: $mg
    border-top: 1px solid $cG2

    &[data-first]
        margin-top: 0
        padding-top: 0
        border: 0

    &[type='h3']
        margin-top: $mg
        padding-top: $mg
        margin-bottom: $mgSm

        .section-title
            font-size: $fzSm

    .section-title
        font-size: $fzMd      

    .section-description
        font-size: $fzXs  

</style>