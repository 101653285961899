<template>
    <table class="table">
        <thead class="table-head">
            <tr class="tableRow">
                <th v-for="header in headers" :key="header" class="col">{{header}}</th>                
            </tr>
        </thead>
        <tbody class="table-body">
            <slot>
            </slot>
        </tbody>
    </table>
</template>

<script>
    export default {
        name: "TableView",
        props: {
            headers: Array,
        },

    }
</script>

<style lang="sass" scoped>
.table   
    font-size: $fzXs
    max-width: 100%
    width: 100%
    overflow: auto
    // display: block
    // height: 100%

    thead
        .row
            background: $cG1
        .col
            font-weight: normal
            color: $cG5

            &:hover
                background: $cG1

    .col    
        // display: table-cell
        background: transparent
        // min-width: 100px
        padding: $mgSm

        &:hover
            color: $cBrand          

        &.index
            background: transparent
            width: 20px

        a
            color: $cPrimary
    
    .tableRow
        // display: flex
        border: 0
        border-bottom: 1px solid $cG1
        min-width: 100%
        text-align: left

        &:hover
            background: $cG1

    .table-body
        .actions
            width: 20px
            padding: 0
            vertical-align: top

</style>