const axios = require('axios').default;

export const UsersMixin = {

    methods: {


        updateUser(user){
            return axios
                    .put(this.$api + 'user/' + user.id, user)
                    .then(response => {
                        return response.data.data
                    })
                    .catch(error => console.log(error))
        },

        getUsers(query, page = 1){
            const baseUrl = process.env.VUE_APP_API_URL + 'users?page=' + page
            const routeUrl = query ? baseUrl + '&' + query : baseUrl
                        
            return axios
                    .get(routeUrl)
                    .then(response => {
                        return response.data.data
                    })
                    .catch(error => console.log(error))
        },

        getSubscriptions(query = null, page = 1){
            const baseUrl = process.env.VUE_APP_API_URL + 'subscription?page=' + page
            const routeUrl = query ? baseUrl + '&' + query : baseUrl
                        
            return axios
                    .get(routeUrl)
                    .then(response => {
                        return response.data.data
                    })
                    .catch(error => console.log(error))
        },

        getUserForms(user_id){
            return axios
                .get(process.env.VUE_APP_API_URL + 'admin/' + user_id + '/forms')
                .then(response => {
                    return response.data
                })
        },

        getUserLimits(user_id){
            return axios
                .get(process.env.VUE_APP_API_URL + 'user/limits/' + user_id )
                .then(response => {
                    return response.data.data
                })
        },

        updateUserLimits(user_id, max_respondents, max_uploads){
            return axios
                .put(process.env.VUE_APP_API_URL + 'user/limits/' + user_id, {max_respondents, max_uploads} )
                .then(response => {
                    return response.data.data
                })
        },

        
        /**
         * Cancel current user subscriptions.
         */
        cancelSubscription(user){
            const subscription_id = user.subscriptions.gateway_subscription_id
            const url = process.env.VUE_APP_API_URL + 'subscription/cancel/' + subscription_id
            return axios
                .post(url)
                .then(response => {
                    return response.data
                })
        },
        

        /**
         * Add a new payment source from a Stripe Token  on this user profile.
         * Also, try to pay any open invoice (default Stripe behaivour)
         */
        async addPaymentSource(token){
            if(!token) return null
            return await axios.post(process.env.VUE_APP_API_URL + "users/paymentsource/" + this.$user.id, {token})
        }   
    
    }



}