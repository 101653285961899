<template>
    <div class="users-page">
        <TopHeader />

        <!-- Dashboard -->
        <main v-if="forms" class="container-fluid main-content mt64">

            <!-- User info -->
            <section class="user-info">
                <div class="account-info mb64">
                    <h1 class="page-title title-xl mb8">{{user.name}}</h1>
                    <p>{{user.email}}</p>
                    <p><a target="_blank" class="link" :href="`https://api.whatsapp.com/send?phone=${user.phone}&text=Oi%20${user.name}, `">{{user.phone}}</a></p>
                    <p>{{user.role}}</p>
                    <div class="user-dates mt16">
                        <p><strong>Criado:</strong> {{user.created_at}}</p>
                        <p><strong>Atualizado:</strong> {{user.updated_at}}</p>
                        <p><strong>Login:</strong> {{user.last_login_at}}</p>

                    </div>
                </div>

                <div v-if="limits" class="plan-info">
                    <h2>Plano e limites <Btn @click.native="openModal('userEdit')" type="boxlink" size="xs">Editar</Btn></h2>
                    <div class="row mb16">
                        <a v-if="user.subscriptions" class="mr16" :href="'https://dashboard.stripe.com/customers/' + user.subscriptions.gateway_customer_id">{{user.subscriptions.gateway_customer_id}}</a>
                        <p> <span class="user-plan"><TagLabel>{{user.plan ? user.plan : 'Free'}} <Icon v-if="subscriptionLocked" class="ml8" name="lock" /></TagLabel> </span></p>
                    </div>
                    
                    <ActionRow title="Respostas" :description="limits.consume.respondents.used + '/' + limits.consume.respondents.limit" class="mb16">
                        <ProgressBar :position="limits.consume.respondents.used" :total="limits.consume.respondents.limit" layout="clean" type="scale"  />
                    </ActionRow>

                    <ActionRow title="Uploads" :description="limits.consume.uploads.used + '/' + limits.consume.uploads.limit" class="mb16">
                        <ProgressBar :position="limits.consume.uploads.used" :total="limits.consume.uploads.limit" layout="clean" type="scale"  />
                    </ActionRow>

                    <BtnDelete v-if="user.plan_level > 1" size="xs" class="mt16" :loading="working.cancelSubscription" @delete="cancelSubscriptionAction">Cancelar plano</BtnDelete>
                    
                    <!-- Modal User -->
                    <Modal title="Editar limites de usuário" :active="modals.userEdit" @modal-close="closeModal('userEdit')">
                        <UserEditModal :limits="limits" @update="updateLimits" :loading="working.updateLimits" />
                    </Modal>
                </div>

            </section>   


            <!-- User data -->
            <section class="user-data">

                <h2 class="section-title">Formulários</h2>
                <p class="description mt4">{{forms.length + ' formulários, ' + totalAnwsers + ' respostas.'}}</p>

                <TableView :headers="table.headers" class="mt16">
                    <tr v-for="form in forms" :key="form.id">
                        <td class="col">{{form.id}}</td>
                        <td class="col">
                            <router-link :to="{name: 'EditorAnswers', params: { slug: form.slug }}">
                            {{form.name}}
                            </router-link>
                        </td>
                        <td class="col">{{form.respondents_count}}</td>
                        <td class="col">{{form.slug}}</td>
                        <td class="col">{{form.created_at}}</td>
                        <td class="col">{{form.status}}</td>
                        <td class="col actions"><ContextMenu :options="formActions" @context:action="formContext($event, form)" /></td>
                    </tr>
                </TableView>
            </section>
        </main>


    </div>
</template>

<script>
import ContextMenu from '../../components/form/ContextMenu.vue'
import BtnDelete from '../../components/form/BtnDelete.vue'
import UserEditModal from '../../components/modal/UserEditModal.vue'
    import ProgressBar from '../../components/ProgressBar.vue'
    import ActionRow from '../../components/ActionRow.vue'
    import SectionHeader from '../../components/SectionHeader.vue'
    import SelectField from '../../components/form/SelectField.vue'
    import Btn from '../../components/form/Btn.vue'
    import Modal from '../../components/Modal.vue'
    import TextField from '../../components/form/TextField'
    import TagLabel from '../../components/TagLabel.vue'
    import TableView from '../../components/TableView.vue'
    import TopHeader from '../../components/TopHeader.vue'
    import { UsersMixin } from '@/mixins/UsersMixin'
    // import { UtilitiesMixin } from '@/mixins/UtilitiesMixin'
    export default {
    name: "UserForms",
    components: {
    TextField, TopHeader, TableView, TagLabel, Modal, Btn, SelectField, SectionHeader, ActionRow, ProgressBar, UserEditModal, BtnDelete, ContextMenu },
    mixins: [UsersMixin],
    props: {
        user_id: [Number, String],
    },
    computed: {
        totalAnwsers() {
            let total = 0
            this.forms.forEach(form => {
                total = total + form.respondents_count
            })
            return total
        }
    },
    data() {
        return {
            forms: null,
            user: null,
            limits: null,
            modals: {
                userEdit: false,
                userLimits: false
            },
            subscriptionLocked: false,
            table: {
                rows: this.forms,
                headers: ["ID", "Nome", "Respostas", "Slug", "Criado", "Status", "+"]
            },

            subscriptions: {
                headers: ["id", "gateway"],
                rows: [
                    {id: 1},
                    {id: 2},
                ]
            },
            
            working:{
                updateLimits: false,
                updateProfile: false,
                cancelSubscription: false,
            },

            formActions: [
                {value: "formClone", label: "Clonar para análise"},
                {value: "formDataExport", label: "Exportar configuração"},
            ]
        }
    },
    
   

    async mounted () {
        this.getUserForms(this.user_id)
            .then(response => {
                this.forms = response.forms
                this.user = response.user
                this.subscriptionLocked = this.user.subscriptions?.status == 'locked' ? true : false
            })

        this.limits = await this.getUserLimits(this.user_id)
        },

    methods: {
        async updateLimits(data){
            this.working.updateLimits = true
            const limits = await this.updateUserLimits(this.user_id, data.respondents, data.uploads)
            this.limits = limits
            this.working.updateLimits = false
        },

        async cancelSubscriptionAction(){
            this.working.cancelSubscription = true
            const cancel = await this.cancelSubscription(this.user)            
            this.working.cancelSubscription = false            
        },

        openModal(name) {
            this.modals[name] = true            
        },

        closeModal(name) {
            this.modals[name] = false            
        },

        formContext(event, form){
            if(event == "formClone") this.formClone(form)
        },

        async formClone(form){
            const clone = await this.$axios.post(process.env.VUE_APP_API_URL + 'admin/form/clone/' + form.slug)
            if(clone.data.id) this.$router.push({name: 'Editor', params: {slug: clone.data.slug}})
        }

    }
}
</script>

<style lang="sass" scoped>

.main-content
    display: flex
    padding: 0 $mgSm

    .user-info        
        width: 400px
        margin-right: $mgLg
        line-height: 140%

        .page-title
            font-size: $fzLg
            line-height: 120%

        .user-dates
            font-size: $fzXs

    .user-data
        width: 100%

.label
    display: block



</style>