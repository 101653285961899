<script>
import ActionRow from '../ActionRow.vue'
import Btn from '../form/Btn.vue'
    import { UsersMixin } from '@/mixins/UsersMixin'
    import TextField from '../form/TextField.vue'
    export default {
        components: { TextField, Btn, ActionRow },
        mixins: [UsersMixin],
        props: {
            limits: Object,
            loading: Boolean
        },

        methods: {
            save() {
                this.$emit('update', {respondents: this.limits.consume.respondents.limit, uploads: this.limits.consume.uploads.limit})
            },
            reset() {
                this.$emit('update', {respondents: null, uploads: null})
            }
        },
        
    }
</script>


<template>
    <div class="user-edit-modal-comp">

        <ActionRow title="Respostas" description="Máximo de respostas por mês" class="mb16">
            <TextField type="number" :data="{}" v-model="limits.consume.respondents.limit"/>
        </ActionRow>

        <ActionRow title="Uploads" description="Máximo de uploads (em MBs) por mês" class="mb32">
            <TextField type="number" :data="{}" v-model="limits.consume.uploads.limit"/>
        </ActionRow>

        <ActionRow title="Redefinir para padrão" description="Retorna os limites para o padrão do plano atual" class="mb32">
            <Btn @click.native="reset" type="secondary" size="xs" class="ml8">Redefinir</Btn>
        </ActionRow>

        <Btn @click.native="save" :loading="loading">Salvar</Btn>

    </div>
</template>


<style lang="sass" scoped>

.row
    .col
        flex: 1

</style>