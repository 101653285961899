<script>
    import Btn from './Btn.vue'
    
    export default {
        components: { Btn },
        name: "BtnDelete",   
        props: {
            type: {type: String, default: "danger"},
            size: String,
        },   
        data() {
            return {
                active: false
            }
        },  

        methods: {
            openDialog() {this.active = true},
            closeDialog() {this.active = false},
            submit() {
                this.$emit("delete")
                this.closeDialog()
            }
        },
    }
</script>

<template>
    <div class="btn-delete-comp">
        <div v-if="active" class="confirmation-box">
            <div class="title mr8">Confirma?</div>
            <div class="buttons">
                <Btn @click.native="submit" type="danger" layout="box" size="xs" >Sim</Btn>
                <Btn @click.native="closeDialog" type="secondary" layout="box" size="xs">Não</Btn>            
            </div>
        </div>
        <Btn @click.native="openDialog" :type="type" :size="size"><slot></slot></Btn>
    </div>
</template>


<style lang="sass" scoped>
.btn-delete-comp
    position: relative

    .confirmation-box   
        background: $cWhite
        box-shadow: 0 0 8px rgba(0,0,0, .2)
        width: 170px
        border-radius: 4px
        position: absolute
        bottom: -50px
        right: 0px
        display: flex
        align-items: center
        padding: $mgXs

        .title
            font-size: $fzXxs
            font-weight: bold

</style>